import React from "react";
import { TextField } from "@material-ui/core";
import SplitPane from "./SplitPane";
import linkedin from '../img/social/Linkedin_2.png'
import bluesky from '../img/Bluesky_Logo.svg.png'
import email from '../img/social/email.png'

export function Contact(props: any) {
  return (
    <SplitPane split="column" picSize="7" title="Contact Me">
      <div style={{display: 'flex', gap: '32px'}}>
      <a href='https://www.linkedin.com/in/allison-usher/'><img src={linkedin} width='100' /></a>
      <a href='https://bsky.app/profile/alysonna.bsky.social'><img src={bluesky} width='100'/></a>
      </div>
    </SplitPane>
  );
}
