import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Main } from "./components/Main";
import { Root } from "./components/Root";
import { ProjectPage } from "./components/ProjectPage";
import Projects from "./components/Projects";
import { Contact } from "./components/Contact";
import { About } from "./components/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Main />,
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "projects",
        element: <Projects />,
      },
      {
        path: "projects/:projectName",
        element: <ProjectPage />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
]);

export function App() {
  return <RouterProvider router={router} />;
}
