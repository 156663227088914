import React from "react";
import { Grid } from "@material-ui/core";
import { ProjectCard } from "./ProjectCard";
import { projects } from "../util/Data";
import SplitPane from "./SplitPane";

function Projects(props: any) {
  return (
    <SplitPane split="column" title="Projects">
      <Grid container alignItems="center" justify="center" spacing={8}>
        {projects.map((project) => {
          return <ProjectCard project={project} />;
        })}
      </Grid>
    </SplitPane>
  );
}

export default Projects;
