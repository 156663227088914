import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@material-ui/core";
import { Styles } from "./Styles";
import { NavLink } from "react-router-dom";

export function Header() {
  const classes = Styles();

  return (
    <AppBar position="static" className={classes.colorPrimary}>
      <Toolbar>
        <NavLink to="/" className={classes.title}>
          <Typography variant="h4" className={classes.title}>
            Allison Usher
          </Typography>
        </NavLink>
        <NavLink to="/about" className={classes.link}>
          <Button color="inherit" className={classes.barLink}>
            About Me
          </Button>
        </NavLink>
        <NavLink to="/projects" className={classes.link}>
          <Button color="inherit" className={classes.barLink}>
            Projects
          </Button>
        </NavLink>
        <NavLink to="/contact" className={classes.link}>
          <Button color="inherit" className={classes.barLink}>
            Contact Me
          </Button>
        </NavLink>
      </Toolbar>
    </AppBar>
  );
}
