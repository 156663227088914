import { Outlet } from "react-router-dom";
import { Header } from "./Header";
import background from "../img/generals/background.jpg";

export function Root() {
  const bkgSize = {
    position: "fixed",
    zIndex: "-1",
    minWidth: "100vw",
    minHeight: "100vh",
  } as const;
  return (
    <>
      <img id="bkgImg" src={background} style={bkgSize} alt="Background" />
      <Header />
      <Outlet />
    </>
  );
}
