import { Button, Paper, Typography } from "@material-ui/core";
import SplitPane from "./SplitPane";
import { NavLink } from "react-router-dom";
import { Styles } from "./Styles";

export function About(){
    const classes = Styles();

    return (
      <SplitPane
        split="row"
        picSize="7"
        img={require("../img/portfolio_pic.JPG")}
      >
        <Paper elevation={3} className={classes.textContainer}>
          <Typography variant='h3'>Hi, I'm Allison</Typography>
          <Typography variant="body1">I'm a full stack web developer of many years with a passion for creating web solutions to 
            every day problems. My work at Robinhood exlemplified this by being a lead part of the front end web solution for building
            their chat customer service from the ground up using Typescript and React. In my personal development time, I primarily
            spend time building personal bots for discord servers, and working on small projects to help myself with my other passion
            Final Fantasy XIV
          </Typography>
          <Typography variant="body1" style={{marginTop: '32px'}}>
            My background includes study at Eastern Washington University in the field of Computer Science, as well as completion of
            Bloom Technology's course on Full Stack Web Development, earning high marks from the instructors for my abilies in problem
            solving
          </Typography>
        </Paper>
      </SplitPane>
    );
}