import React from "react";
import {
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Button,
  CardContent,
  CardActions,
  Grid,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export function ProjectCard({ project }: any) {
  const navigate = useNavigate();
  return (
    <Grid item>
      <Card style={{ maxWidth: 300 }}>
        <CardActionArea
          onClick={() => {
            navigate(
              `/projects/${project.title.replace(/ /g, "_").toLowerCase()}`,
            );
          }}
        >
          <CardMedia style={{ height: 140 }} image={project.image} />
          <CardContent>
            <Typography variant="h5">{project.title}</Typography>
            <Typography variant="body2" component="p">
              {project.description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          {project.git && (
            <Button size="small" color="primary" href={project.git}>
              View on Github
            </Button>
          )}
          {project.deploy && (
            <Button size="small" color="primary" href={project.deploy}>
              View in action
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
}
