type Tech = { title: string; link: string; highlight?: boolean; img?: string };

const techs: Record<string, Tech> = {
  java: {
    title: "Java",
    link: "https://www.java.com/en/",
    highlight: true,
  },
  sqlite: {
    title: "Sqlite",
    link: "https://www.sqlite.org/index.html",
  },
  discord4j: {
    title: "Discord4J",
    link: "https://github.com/Discord4J/Discord4J",
  },
  lavaplayer: {
    title: "LavaPlayer",
    link: "https://github.com/sedmelluq/lavaplayer",
  },
  react: {
    title: "React",
    link: "https://reactjs.org/",
    highlight: true,
  },
  xivapi: {
    title: "XIVAPI",
    link: "https://xivapi.com/",
  },
  typescript: {
    title: "Typescript",
    link: "https://www.typescriptlang.org/",
    highlight: true,
  },
};

type Project = {
  image: string;
  title: string;
  description: string;
  git?: string;
  deploy?: string;
  pageInfo?: any;
  details: string;
  technologies: Tech[];
};

const projectsMap: Record<string, Project> = {
  rhChat: {
    image: "https://newsroom.aboutrobinhood.com/wp-content/uploads/sites/2/2023/01/Blog_24_7Chat.gif",
    title: "Robinhood Customer Service Chat",
    description: "Chat customer support for Robinhood",
    details: "Worked on a team of 4 to build a web chat client for Twilio utilizing React and Typescript, as well as designing the agent side of the client by extending Twilio's flex platform",
    deploy: 'http://robinhood.com/contact',
    technologies: [techs.react, techs.typescript],
  },
  ffxiv: {
    image: require("../img/ffxiv.png"),
    title: "Final Fantasy Character Lookup",
    description:
      "SPA React application that allows you to search for characters from the game Final Fantasy XIV.",
    git: "https://github.com/allyjay317/React-Redux-App/tree/allison-usher",
    deploy: "https://sleepy-joliot-9e176f.netlify.app/",
    pageInfo: {},
    details:
      "Simple character look up for the video game Final Fantasy XIV. Non functional due to XIVAPI depreciation",
    technologies: [techs.react, techs.xivapi],
  },
  portfolio: {
    image: require("../img/portfolio.png"),
    title: "Portfolio",
    description: "The Site you're on!",
    git: "https://github.com/allyjay317/Portfolio",
    deploy: "www.allisonusher.com",
    details: "Portfolio site for myself",
    technologies: [techs.react, techs.typescript],
  },
};

export const projects = Object.values(projectsMap);

export const skills = Object.values(techs)
  .filter((t) => t.highlight)
  .map((skill) => ({
    ...skill,
    notableProjects: projects.filter((p) => p.technologies.includes(skill)),
  }));
