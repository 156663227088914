import { Typography, Paper, Button } from "@material-ui/core";
import { Styles } from "./Styles";
import SplitPane from "./SplitPane";
import { NavLink } from "react-router-dom";

import * as techs from "../img/techs/";

export function Main() {
  const classes = Styles();

  return (
    <SplitPane
      split="row"
      picSize="7"
      img={require("../img/portfolio_pic.JPG")}
    >
      <Paper elevation={3} className={classes.textContainer}>
        <Typography variant="h1" className={classes.title}>
          Allison Usher
        </Typography>
        <Typography variant="h3">Full Stack Web Developer</Typography>
        <NavLink to="/about" className={classes.link}>
          <Typography variant="h5">
            <Button color="inherit" className={classes.barLink}>
              About Me
            </Button>
          </Typography>
        </NavLink>
        <div
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "space-between",
          }}
        >
          {Object.values(techs).map((t) => (
            <img src={t} width="50px" />
          ))}
        </div>
      </Paper>
    </SplitPane>
  );
}
